@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}



	.banner-forms {
		position: relative;
		min-height: 620px;
		height: 100vh;
		z-index: 3;

		@media (max-width:992px) {
			margin-top: 86px;
		}

		.cover-video-bl {
			height: 100vh;
			overflow: hidden;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			position: relative;

			@media (max-width:767px) {
				content: "";
				width: 100%;
				bottom: 0;
				height: 8px;
				background: rgba(0, 0, 0, .2);
				left: 0;

			}

			.video {
				max-width: 100%;
				width: 100%;
				position: relative;
				transform: translateY(-50%);
				top: 50%;
				background-size: cover;
				background-position: center;

				@media (max-width: 1370px) {
					top: 0;
					transform: translateY(0);
				}
			}

			@media (max-width: 1370px) {
				height: auto;
			}

		}

		.banner-info {
			position: absolute;
			top: 45%;
			z-index: 4;
			text-align: center;
			color: var(--white);
			width: 100%;


			h1 {
				font-weight: 800;
				font-size: 70px;
				text-shadow: 0 0 10px rgb(0 0 0 / 30%);
			}

			h2 {
				font-size: 36px;
				text-shadow: 0 0 10px rgb(0 0 0 / 30%);
			}
		}

		@media (max-width:767px) {
			min-height: 560px;

		}

		@media (max-width:992px) {
			height: calc(100vh - 80px);
		}

		.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: 40%;
				left: 0;
				width: 100%;

				@media (max-width:992px) {
					display: none;
				}


				button {
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 1px solid #ddd !important;
					border-radius: 50%;
					box-shadow: 0 0 10px rgba(0, 0, 0, .5);
					background-color: var(--white) !important;
					position: absolute;
					left: 40px;
					font-size: 34px;

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--black);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {
							opacity: .3;
						}
					}

					&.owl-next {
						position: absolute;
						right: 40px;
						left: initial;

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		&:before {
			background: rgb(255, 255, 255);
			background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.50) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			top: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		}

		&:after {

			background: rgb(255, 255, 255);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.50) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			bottom: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		}

		@media (max-width:992px) {
			height: calc(100vh - 80px);
		}

		.search-form-area {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			flex-wrap: wrap;

			.form-flex {
				padding: 7px;

				@media (max-width:992px) {
					width: 100%;
				}

				.form-bx-area {


					button.form-control {
						min-width: 230px;

						@media (max-width:1340px) {
							font-size: 18px;
						}
					}


				}

				.fa,
				.far {
					position: absolute;
					right: 2px;
					top: 44px;
					color: var(--black);
					z-index: 2;
					font-size: 20px;
					pointer-events: none;

					@media (max-width:1340px) {
						top: 28px;
					}

					@media (max-width:1140px) {
						top: 13px;

					}

					&.shadow {
						font-size: 65px;
						top: 0px;
						color: var(--grey);
						z-index: 1;
						right: 14px;

						@media (max-width:1340px) {
							font-size: 48px;
						}

						@media (max-width:1140px) {
							font-size: 37px;
							top: -4px;
							opacity: .5;
						}
					}

					&.fa-map-marker-alt {
						right: 8px;

						@media (max-width:1140px) {
							right: 0;
						}

						&.shadow {
							@media (max-width:1140px) {
								right: 9px !important;
							}
						}
					}
				}


				.form-control {
					background-color: var(--white);
					border: none;
					border-radius: 0;
					width: 100%;
					height: 46px;
					box-shadow: inherit;
					font-family: var(--dfont2);
					padding: 10px 16px;
					font-size: 18px;
					text-align: left;
				}


			}

			.form-btn {
				padding: 7px;

				@media (max-width:992px) {
					width: 100%;
					text-align: center;
				}

				.btn {
					align-items: center;
					display: flex;
					justify-content: center;
					font-size: 18px;
					border-radius: 0;
					font-family: var(--dfont2);
					font-weight: bold;
					height: 46px;
					position: relative;
					min-width: 145px;
				}
			}
		}

		.banner-slide {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: -1;

			.owl-dots {
				display: none;
			}

			.owl-item {
				position: relative;
			}

			.item {
				width: 100%;
				min-height: 620px;
				height: 100vh;
				background-size: cover;
				background-position: no-repeat;
				background-position: center;

				@media (max-width:992px) {
					height: calc(100vh - 80px);
				}

				@media (max-width:767px) {
					min-height: 560px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&.banner1 {
					background-image: url(RESOURCE/img/banner1.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner1.jpg);
					}
				}

				&.banner2 {
					background-image: url(RESOURCE/img/banner2.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner2.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/banner3.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner3.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/banner4.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner4.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/banner5.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner5.jpg);
					}
				}

				&:after {
					height: 80px;
					bottom: 0;
					left: 0;
					right: 0;
					position: absolute;
					display: block;
					background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
					content: "";
					opacity: 0.6;
				}
			}


			.owl-dots {
				bottom: 60px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				@media (max-width:992px) {
					display: none;
				}

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 4px solid var(--white);
					margin: 0 4px;

					&.active {
						border-bottom-color: var(--primary);
					}
				}
			}
		}


		.search-box-home {
			position: absolute;
			bottom: 85px;
			text-align: center;
			width: 100%;
			z-index: 5;
			padding: 0 50px;

			@media (max-width:992px) {
				position: relative;
				bottom: 18px;
				padding: 0 20px;
			}
		}

		.searchbar {

			display: inline-block;
			text-align: left;
			background-color: rgba(0, 0, 0, 0.4);
			border-radius: 0;

			@media (max-width:992px) {
				width: 100%;
				padding: 10px 20px 10px;
			}

			/*@media (min-width:993px) {
				max-width: 1200px;
				width: 100%;
			}*/


			.searchbox {

				.btn-primary {
					&:hover {
						.fa-search {
							color: var(--primary);
						}
					}
				}

				input,
				.search-btn,
				select {
					border-radius: 0;
				}

				.search-btn {
					width: 100%;
				}

				.search-popup-trigger {
					cursor: pointer;
				}

				.form-control:disabled,
				.form-control[readonly] {
					background-color: white;
				}
			}


		}

		.contact-section {
			background-color: #fff !important;
		}






		@media (max-width:767px) {

			.iheader {
				background: var(--introMobileImg) no-repeat center;
				-webkit-background-size: cover;
				background-size: cover;

				.intro {
					.hero {
						h2 {
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	@media all and (min-width: 320px) and (max-width: 480px) {
		.banner-forms {
			min-height: 375px;
			height: auto;


			.search-box-home {
				bottom: 0;
				padding: 0;
			}

			.banner-info {
				display: none;
			}
		}
	}

	@media all and (min-width: 481px) and (max-width: 640px) {
		.banner-forms {
			min-height: 465px;
			height: auto;

			.search-box-home {
				bottom: 0;
				padding: 0;
			}

			.banner-info {
				top: 150px;

				h1 {
					font-size: 30px;
				}

				h2 {
					font-size: 20px;
				}
			}
		}
	}

	@media all and (min-width: 641px) and (max-width: 767px) {
		.banner-forms {
			min-height: 515px;
			height: auto;


			.search-box-home {
				bottom: 0;
				padding: 0;
			}

			.banner-info {
				top: 170px;

				h1 {
					font-size: 30px;
				}

				h2 {
					font-size: 20px;
				}
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 991px) {
		.banner-forms {
			min-height: 555px;
			height: auto;


			.search-box-home {
				bottom: 4px;
				padding: 0;
			}

			.banner-info {
				top: 35%;

				h1 {
					font-size: 40px;
				}

				h2 {
					font-size: 30px;
				}
			}
		}
	}

	@media all and (min-width: 992px) and (max-width: 1140px) {
		.banner-forms {
			min-height: 650px;
			height: auto;


			.search-box-home {
				bottom: 0;
				padding: 0;
			}

			.banner-info {
				top: 35%;

				h1 {
					font-size: 50px;
				}

				h2 {
					font-size: 35px;
				}
			}
		}
	}

	@media all and (min-width: 1141px) and (max-width: 1500px) {
		.banner-forms {
			min-height: 630px;
			height: auto;

		}
	}
}