@import "setup.css";

.header-style2 {
	.vo-nav-bar {
		&.mainHeader {
			.logo {
				a {
					background-image: url(RESOURCE/img/logo.jpg) !important;
					display: block;
					background-repeat: no-repeat;
					background-position: 50%;
					width: 45px;
					height: 55px;
					background-size: 45px;

					@media (max-width:1200px) {
						width: 45px;
						height: 55px;
						background-size: 45px;
					}
				}
			}
		}
	}

}

.vo-nav-bar {
	.topbar-nav {
		background-color: var(--grey-dark);
		padding: 8px 25px;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		color: var(--white);

		a {
			color: var(--white);
		}

		@media (max-width:1200px) {
			display: none;
		}
	}

	.search-bl-mob {
		display: none;
	}

	.show-mob {
		&.mob-icon {

			@media (max-width:1200px) {
				display: block !important;
			}


			a {
				font-size: 24px;
				color: var(--grey-dark);
				margin-left: 10px;
			}
		}
	}

	&.mainHeader {
		position: fixed;
		width: 100%;
		top: 0px;
		z-index: 50;
		display: flex;
		align-items: center;
		padding: 14px 0;
		transition: all ease-in .2s;
		background-color: var(--primary);

		@media (max-width:1200px) {
			padding: 10px 0;
			top: 0;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;

			@media (max-width:1200px) {
				padding: 0;
				margin-left: 0;
			}

			.srch-btn {
				font-size: 20px;
				color: var(--white);
				padding: 2px 4px;

				@media (max-width:1200px) {
					margin-right: 5px;
				}
			}

			.nav-right {
				display: flex;
				justify-content: flex-end;
				max-width: 215px;
				margin-top: 30px;

				@media (max-width:1200px) {
					margin-top: 0;
				}

				.search-form {
					@media (max-width:1200px) {
						display: none !important;
					}
				}

				.fav-count {
					color: var(--grey-dark);
					position: relative;
					margin-left: 5px;
					top: 6px;

					@media (max-width:1200px) {
						margin-right: 5px;
					}

					.fa {
						font-size: 24px;
					}

					.sub-count {
						display: block;
						position: absolute;
						top: -6px;
						left: 7px;
						width: 18px;
						height: 18px;
						background: red;
						background: var(--grey-lighten);
						color: var(--grey-dark);
						text-align: center;
						font-size: 12px;
						line-height: 18px;
						border-radius: 50%;
					}
				}
			}
		}

		.logo {
			padding: 5px;
			border: 1px solid #000;
			background: #fff;
			display: flex;
			z-index: 51;
			position: relative;

			img {
				width: 95px;
			}

			a {
				background-image: url(RESOURCE/img/logo.jpg);
				width: 90px;
				height: 100px;
				display: flex;
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: 92px;
				border: 1px solid #000;

				@media (max-width:1200px) {
					width: 45px;
					height: 55px;
					background-size: 45px;
				}
			}


		}



		.topNav {
			margin-top: 35px;

			.navbar {
				min-height: inherit;
				margin: 0;

				@media (max-width:1200px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--white);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;
				top: 28px;

				@media (max-width:1200px) {
					display: inline-block;
					position: absolute;
					right: 20px;
				}
			}



			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0px;
					margin: 0px 5px;

					@media (max-width:1240px) {
						margin: 0 2px;
					}

					a {
						font-size: 14px;
						color: var(--black);
						display: inline-block;
						padding: 6px 10px;
						background: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: bold;
						text-shadow: 0 0 10px rgba(0, 0, 0, .3);
						border-radius: 0;

						@media (max-width:1240px) {
							padding: 5px 10px;
							font-size: 14px;
						}

						@media (max-width:1200px) {
							padding: 7px 0;

						}

						span {
							padding: 3px 5px;
							border-radius: 0;
							background-color: var(--white);
							color: var(--secondary);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							background-color: var(--white);

						}

						.dropdown-menu {
							display: block;
						}
					}

					.inmobi-btn {
						background-color: #FFF;

					}

					.dropdown-menu {
						min-width: 260px;
						left: 0px;
						margin: 0px;
						padding: 0;
						background-color: var(--primary);
						border: none;
						border-radius: 0;
						margin-top: -1px;

						@media (max-width:1200px) {
							ul {
								padding: 0;
							}
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid rgba(255, 255, 255, .2);
							margin: 0px;
							padding: 8px 20px;

							&:last-child {
								border-bottom: none;
							}
						}

						a {
							font-size: 14px;
							display: block;
							padding: 0;

							&:hover {
								color: var(--secondary);
							}
						}
					}
				}

			}
		}
	}

	&.affix {
		background-color: var(--primary);

		@media (max-width:1200px) {
			top: 0;
		}


		.navbar-area {

			.logo {
				a {
					background-image: url(RESOURCE/img/logo.jpg) !important;
					display: block;
					background-repeat: no-repeat;
					background-position: 50%;
					width: 45px;
					height: 55px;
					background-size: 45px;

					@media (max-width:1200px) {
						width: 45px;
						height: 55px;
						background-size: 45px;
					}
				}
			}

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;

							span {
								background-color: var(--white);
								color: var(--secondary);
							}
						}
					}
				}
			}

			.srch-btn {
				/*color: var(--primary) !important;*/
			}

		}
	}

	@media (max-width:1200px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}

	@media screen and (max-width: 1200px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media (max-width: 1200px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 0;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
	}


	.navbar-nav li {
		&.search-bl-mob {
			padding: 0 35px;
			margin-top: 20px;

			@media (max-width:1200px) {
				display: block !important;
			}

			.input-group.search {
				.far {
					display: block !important;
					padding: 3px 0px !important;

				}
			}
		}
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 0;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}

		i.fal,
		i.far {
			display: none;
		}
	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 75vh;
		padding-bottom: 35px;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}
}

.inner-page {
	padding: 11px 0 !important;


	.navbar-area {
		.logo {
			a {
				background-image: url(RESOURCE/img/logo.jpg) !important;
				width: 45px;
				height: 55px;
				display: inline-block;
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: 45px;
			}
		}

		.topNav {
			ul {
				li {
					a {
						text-decoration: inherit;
						text-shadow: none;
					}
				}
			}
		}

		.srch-btn {
			color: var(--primary) !important;

			i {
				color: var(--white) !important;
			}
		}

	}
}

@media all and (min-width: 1200px) and (max-width: 1400px) {
	.vo-nav-bar.mainHeader {
		.navbar-area {
			padding: 0;
		}

		. {
			a {
				max-width: 70px;
				background-size: 65px;
				height: 70px;
			}
		}

		.topNav {
			ul {
				li {
					a {
						padding: 5px 5px;
						font-size: 12px;
					}
				}
			}
		}
	}
}

@media all and (min-width: 1200px) and (max-width: 1515px) {
	.vo-nav-bar.mainHeader {
		.navbar-area {
			padding: 0;
		}

		.logo {
			a {
				width: 45px;
				background-size: 45px;
				height: 55px;
			}
		}

		.topNav {
			ul {
				li {
					a {
						font-size: 13px;
					}
				}
			}
		}
	}
}