.payment-view {
	min-height: 500px;
	padding-top: 120px;

	.inner-banner {
		display: none;
	}

	.payment-inner {
		padding: 75px 0 130px 0;
	}
}