.inner-banner {
	background-image: url(RESOURCE/img/inner-banner.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 390px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}
}

.page-not-found {
	.page-404 {
		padding: 40px 0 100px 0;
		text-align: center;

		h2 {
			margin: 0 0 30px 0;

			i {
				color: var(--primary);
				font-size: 130px;

			}
		}

		h3 {
			margin: 0 0 30px 0;
		}
	}
}